<template>
  <v-dialog v-model="model" max-width="600" persistent>
    <div class="tw-w-full tw-mx-auto tw-bg-white tw-p-4">
      <div class="tw-font-bold tw-text-gray-700">Vincular Franqueado</div>
      <div class="tw-my-2 tw-text-xs tw-text-gray-500">
        O franqueado deve ter cadastro no sistema
      </div>
      <div class="tw-mt-5">
        <input
          type="text"
          class="tw-bg-white tw-p-2 tw-w-full md:tw-w-1/2 tw-rounded tw-outline-none tw-border tw-border-solid"
          name="name"
          label="Buscar usuário"
          id="id"
          v-model="seachUser"
          placeholder="Buscar por novo franqueado"
        />
      </div>
      <div
        class="tw-overflow-y-scroll tw-max-h-48 tw-mt-3 scroll-modern tw-p-1 tw-px-2 tw-bg-gray-50 tw-rounded tw-shadow-inner tw-shadow-md tw-border tw-border-solid tw-border-gray-200"
      >
        <div v-if="getUsers.length == 0" class="tw-font-bold">
          Nenhum usuario encontrado
        </div>
        <div
          class="tw-p-3 tw-my-1 tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-rounded tw-text-sm"
          :key="index"
          v-for="(user, index) in getUsers"
        >
          <div class="tw-flex tw-flex-row tw-items-center">
            <div class="tw-flex-1">
              <div class="tw-text-gray-700 tw-font-bold">{{ user.nome }}</div>
              <div class="tw-text-gray-500">{{ user.email }}</div>
            </div>
            <div class="tw-flex-none">
              <button
                v-if="!usuarioExiste(user)"
                @click="addUserTotem(user)"
                class="tw-p-2 tw-border-2 tw-border-solid tw-border-black tw-rounded-full"
              >
                <v-icon color="black" size="20">mdi-account-plus</v-icon>
              </button>
              <div
                v-else
                class="inline-block tw-p-2 tw-border-2 tw-border-solid tw-border-green-400 tw-rounded-full"
              >
                <v-icon
                  size="20"
                  color="fill"
                  class="tw-fill-current tw-text-green-400"
                  >mdi-check-all</v-icon
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="tw-mt-2 tw-text-right">
        <button
          class="tw-bg-gray-100 tw-font-bold tw-px-3 tw-py-2 tw-text-gray-700 tw-rounded tw-border tw-border-solid tw-border-gray-300"
          @click="$emit('close')"
        >
          Fechar
        </button>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { db } from "@/service/firebase";
import { addDoc, collection, getDocs } from "firebase/firestore";
export default {
  props: {
    usersTotem: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      model: true,
      users: [],
      seachUser: "",
    };
  },
  computed: {
    getUsers() {
      return this.users.filter((user) => {
        let userName = user.nome.toLowerCase();
        let userEmail = user.email.toLowerCase();
        let search = this.seachUser;
        return userName.match(search) || userEmail.match(search);
      });
    },
  },
  mounted() {
    this.requestUsers();
  },
  methods: {
    async addUserTotem(user) {
      console.log(user);
      let ref = collection(db, "userFranqueado");
      let userID = user.userID;
      let totemID = this.$route.params.id;
      this.$emit("update");
      await addDoc(ref, {
        userID,
        totemID,
      });
    },
    usuarioExiste(user) {
      let busca = this.usersTotem.find((item) => item.email === user.email);
      if (busca) {
        return true;
      } else {
        return false;
      }
    },
    async requestUsers() {
      this.users = [];
      let referencia = collection(db, "users");
      var snapshots = await getDocs(referencia);
      snapshots.forEach((doc) => {
        let user = { userID: doc.id, ...doc.data() };
        this.users.push(user);
      });
    },
  },
};
</script>

<style></style>
