<template>
  <div>
    <v-dialog v-model="model" max-width="400" persistent>
      <div class="tw-w-full tw-mx-auto tw-bg-white tw-p-4">
        <div class="tw-font-bold tw-text-gray-700">Desvincular Franqueado</div>
        <div
          class="tw-text-gray-500 tw-bg-gray-100 tw-rounded-md tw-p-3 tw-mt-2 tw-text-sm"
        >
          <div>{{ user.nome }}</div>
          <div>{{ user.email }}</div>
        </div>
        <div class="tw-my-2 tw-text-sm tw-text-gray-500">
          Digite a palavra <b>{{ wordVerify }}</b> corretamente, para completar
          o processo
        </div>
        <div class="tw-my-4">
          <input
            type="text"
            class="tw-bg-white tw-p-2 tw-w-full tw-rounded tw-outline-none tw-border tw-border-solid"
            name="name"
            id="id"
            v-model.trim="confirmWord"
            placeholder="Digite a palavra corretamente"
          />
        </div>
        <div class="tw-mt-2 tw-text-right tw-grid tw-grid-cols-2">
          <div class="tw-text-left">
            <button
              class="tw-bg-gray-100 tw-font-bold tw-px-3 tw-py-2 tw-text-gray-700 tw-rounded tw-border tw-border-solid tw-border-gray-300"
              @click="$emit('close')"
            >
              Fechar
            </button>
          </div>
          <div class="tw-text-right">
            <button
              class="tw-bg-red-600 tw-font-normal tw-px-3 tw-py-2 tw-text-white tw-rounded tw-border tw-border-solid tw-border-gray-100 disabled:tw-bg-gray-50 disabled:tw-text-gray-300"
              @click="removerUser()"
              :disabled="disabledRemove"
            >
              Sim, remover
            </button>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { deleteDoc, doc } from "@firebase/firestore";
import { db } from "@/service/firebase";
export default {
  props: {
    user: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      model: true,
      confirmWord: "",
      wordVerify: "remover",
      disabledRemove: true,
    };
  },
  watch: {
    confirmWord(val) {
      let compressVal = val;
      let compressVerify = this.wordVerify.toLowerCase();
      if (val) {
        compressVal = val.toLowerCase();
      }
      if (compressVal == compressVerify) this.disabledRemove = false;
      else this.disabledRemove = true;
    },
  },
  methods: {
    async removerUser() {
      let userFranqueadoID = this.user.userFranqueadoId;
      await deleteDoc(doc(db, "userFranqueado", userFranqueadoID));
      this.$emit("update");
      this.$emit("close");
    },
  },
};
</script>

<style></style>
