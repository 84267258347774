<template>
  <div
    id="item"
    class="tw-items-baseline tw-block tw-mx-auto tw-my-1 tw-shadow-xs tw-border-gray-200 tw-border tw-rounded-md tw-bg-white"
  >
    <div class="tw-p-4 md:tw-grid md:tw-grid-cols-6 tw-items-center">
      <div class="md:tw-col-span-3">
        <div
          class="tw-text-gray-600 tw-font-semibold tw-text-sm tw-wide md:pb-0 pb-2"
        >
          {{ item.nome }}
        </div>
        <div class="tw-break-words tw-text-sm tw-text-gray-500 md:pb-0 pb-2">
          {{ item.email }}
        </div>
      </div>

      <div class="md:tw-col-span-1">
        <div
          class="tw-break-words tw-text-sm tw-text-gray-400 tw-border tw-border-solid tw-border-gray-200 tw-inline-block tw-p-1 tw-rounded tw-font-normal"
        >
          {{ getNamePermissao(item.role) }}
        </div>
      </div>
      <div class="tw-text-right sm:tw-col-span-2">
        <button
          @click="$emit('removerUser')"
          class="tw-px-2 tw-py-1 tw-border tw-border-solid tw-border-red-500 tw-bg-red-500 tw-rounded"
        >
          <v-icon size="20" class="tw-text-red-50" color="fill"
            >mdi-delete</v-icon
          >
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { db } from "@/service/firebase";
import { roleListSelect, roleNames } from "@/constants/role";

export default {
  components: {},
  data() {
    return {
      nome: this.item.nome,
      role: this.item.role,
      listaRole: [],
      modalResetPassword: false,
      modalEditUser: false,
      modalDeleteUser: false,
      loading: false,
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  beforeMount() {
    this.listaRole = roleListSelect;
  },
  methods: {
    getNamePermissao(PERM) {
      return roleNames[PERM];
    },
    editar_user(uId) {
      updateDoc(doc(db, "users", uId), {
        nome: this.nome,
        role: this.role,
      })
        .then(() => {
          this.modalEditUser = false;
          this.$emit("update");
          this.$root.sucessoModal("Usuário salvo com sucesso!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          this.$root.errorModal("Ocorreu um erro inesperado.");
        });
    },
    deletar_user(uId) {
      deleteDoc(doc(db, "users", uId))
        .then(() => {
          this.modalDeleteUser = false;
          this.$emit("update");
          this.$root.sucessoModal("Usuário deletado com sucesso!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);
          this.$root.errorModal("Ocorreu um erro inesperado.");
        });
    },
    reset_senha(email) {
      const auth = getAuth();
      sendPasswordResetEmail(auth, email)
        .then(() => {
          this.modalResetPassword = false;
          this.$root.sucessoModal("Email para redefinição de senha enviado!");
        })
        .catch((error) => {
          const errorCode = error.code;
          console.log(errorCode);

          switch (errorCode) {
            case "auth/user-not-found":
              this.$root.errorModal("Usuário não encontrado.");
              break;

            case "auth/invalid-email":
              this.$root.errorModal("Email inválido.");
              break;

            case "auth/missing-email":
              this.$root.errorModal("Digite um email!");
              break;
          }
        });
    },
  },
};
</script>

<style></style>
