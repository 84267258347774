<template>
  <div>
    <div
      class="tw-px-9 tw-py-3 tw-items-center tw-justify-left tw-flex tw-bg-gray-100"
      @click="$router.push('/dashboard/totem/lista-totem')"
    >
      <button
        class="tw-bg-transparent tw-rounded-full hover:tw-bg-gray-200 tw-fill-current tw-group tw-text-white"
      >
        <v-icon
          size="30"
          class="tw-text-gray-700 tw-fill-current tw-stroke-current group-hover:tw-text-pink-700"
          >mdi-arrow-left</v-icon
        >
      </button>
    </div>
    <div class="tw-grid tw-grid-cols-6 tw-items-center">
      <div class="sm:tw-col-span-4 tw-col-span-6">
        <div class="tituloContent sm:tw-text-left tw-text-center">
          Franqueados
        </div>
      </div>
      <div class="sm:tw-col-span-2 sm:tw-p-0 tw-p-4 tw-col-span-6">
        <div class="sm:tw-text-right tw-text-center">
          <button
            class="tw-text-white tw-py-2 tw-px-5 tw-rounded-md tw-mr-0 sm:tw-mr-4 tw-bg-pink-600 hover:tw-bg-pink-500 tw-transition-colors tw-duration-500"
            @click="modalAdicionarUser = true"
          >
            Vincular Franqueado
          </button>
        </div>
      </div>
    </div>
    <div class="tw-pt-0 tw-px-5">
      <div class="tw-mt-5">
        <div
          class="tw-p-4 tw-hidden md:tw-grid md:tw-grid-cols-6 tw-items-center tw-font-bold tw-text-gray-500"
        >
          <div class="md:tw-col-span-3">Nome</div>
          <div class="md:tw-col-span-1">Permissão</div>
          <div class="md:tw-col-span-2 tw-text-right">Opções</div>
        </div>
        <vCardUser
          :item="user"
          :key="index"
          v-for="(user, index) in getUsers"
          @removerUser="confirmaExcluir(user)"
        />
      </div>
    </div>
    <vConfirmRemoveUser
      @update="buscarUsuariosDoTotem"
      @close="modalRemoveUser = false"
      @confirm="excluirUser"
      v-if="modalRemoveUser == true"
      :user="userSelected"
    />
    <vAdicionar
      @close="modalAdicionarUser = false"
      @update="buscarUsuariosDoTotem"
      v-if="modalAdicionarUser"
      :usersTotem="users"
    />
  </div>
</template>

<script>
import {
  collection,
  where,
  query,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore";
import { db } from "@/service/firebase.js";
import vCardUser from "./userCard.vue";
import vAdicionar from "./adicionar.vue";
import vConfirmRemoveUser from "./confirmRemoveUser.vue";
export default {
  components: {
    vCardUser,
    vAdicionar,
    vConfirmRemoveUser,
  },
  data() {
    return {
      users: [],
      modalAdicionarUser: false,
      modalRemoveUser: false,
      userSelected: {},
    };
  },
  mounted() {
    this.buscarUsuariosDoTotem();
  },
  computed: {
    getUsers() {
      return this.users;
    },
  },
  methods: {
    excluirUser() {
      // será a função de exclusão
    },
    confirmaExcluir(user) {
      // será a abertura do modal
      this.userSelected = user;
      this.modalRemoveUser = true;
    },
    async incluirUsuarioNaLista(userID, userFranqueadoId) {
      let docRef = doc(db, "users", `${userID}`);
      let snap = await getDoc(docRef);
      let payload = {
        userFranqueadoId: userFranqueadoId,
        ...snap.data(),
      };
      this.users.push(payload);
    },
    async buscarUsuariosDoTotem() {
      this.users = [];
      let totemID = this.$route.params.id;
      let referencia = query(
        collection(db, "userFranqueado"),
        where("totemID", "==", `${totemID}`)
      );
      var snapshots = await getDocs(referencia);
      snapshots.forEach((doc) => {
        this.incluirUsuarioNaLista(doc.data().userID, doc.id);
      });
    },
  },
};
</script>

<style></style>
